import { isBrowser } from "./constants";

export function slugify(string: string) {
  return string
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/&/g, "") // remove &
    .replace(/[^\w\-]+/g, "") // Remove all non-word characters
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

export function sanitizeTitle(title: string) {
  return title
    .replace(/&amp;/g, "&") // replace &amp; with &
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, ""); // Trim - from end of text
}

export function decodeShopifyId(shopifyId: string) {
  if (isBrowser) {
    const isEncoded = !shopifyId.includes("gid") && shopifyId.includes("==");
    const idBreakDown = isEncoded
      ? window.atob(shopifyId).split("/")
      : shopifyId.split("/");
    return idBreakDown[idBreakDown.length - 1];
  }
}

export function encodeShopifyId(
  id: string,
  connection: string = "ProductVariant"
) {
  if (isBrowser) {
    const shopifyId = window.btoa(`gid://shopify/${connection}/${id}`);
    return shopifyId;
  }
}

export const captureLinkAndSendGAevent = function(
  url: string,
  eventName: string
) {
  try {
    window.gtag("event", eventName, {
      event_callback: function() {
        document.location = url;
      }
    });
  } catch (error) {
    console.log({ error });
    document.location = url;
  }
};

export const sendGAevent = (eventName: string) => {
  try {
    if (eventName) {
      window.gtag("event", eventName);
    }
  } catch (error) {
    console.log({ error });
  }
};

export const addExternalScript = (url: string, callback?: () => void) => {
  if (!isBrowser) {
    return;
  }
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  if (callback) {
    script.onload = callback;
  }
  document.body.appendChild(script);
};

// Cookie helpers
export function setCookie(cname: string, cvalue: string, exdays: number = 0) {
  const date = new Date();
  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = exdays ? `expires=${date.toUTCString()}` : null;
  document.cookie = `${cname}=${encodeURIComponent(cvalue)};${expires &&
    expires};path=/`;
}

export function getCookie(cname: string) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export const uppercaseFirstLetter = (str: string) => {
  const words = str
    .trim()
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.substring(1));
  return words.join(" ");
};

// Convert string to sha256 hash
export const sha256 = async (str: string) => {
  // encode as UTF-8
  const strBuffer = new TextEncoder().encode(str);
  // hash the message
  const hashBuffer = await crypto.subtle.digest("SHA-256", strBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join("");
  return hashHex;
};

// TikTok S2S events API triggers
export const sendS2StikTokEvent = async (customerData: {
  email?: string;
  phone?: string;
  userID?: string;
  eventID?: string;
  eventName?: string;
}) => {
  const {
    email = null,
    phone = null,
    userID = null,
    eventID = null,
    eventName = "PageView"
  } = customerData;

  const emailHash = email ? await sha256(email) : "";
  const phoneHash = phone ? await sha256(phone) : "";
  const userIDhash = userID ? await sha256(userID) : "";

  const userAgent = isBrowser
    ? encodeURIComponent(window.navigator.userAgent)
    : "";
  const url = isBrowser ? encodeURIComponent(window.location.href) : "";
  const sessionID =
    isBrowser && window.sessionStorage.getItem("tt_sessionId")
      ? encodeURIComponent(
          // @ts-expect-error
          window.sessionStorage.getItem("tt_sessionId")?.replaceAll('"', "")
        ) ||
        eventID ||
        ""
      : "";

  try {
    const response = await fetch(
      `/api/send-tiktok-events?url=${url}&email=${emailHash}&phone=${phoneHash}&userID=${userIDhash}&userAgent=${userAgent}&eventID=${sessionID}&eventName=${eventName}`
    ).then(res => {
      return res;
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export function notNil<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export const pushEventToGTM = (eventName: string) => {
  var dataLayer = (window.dataLayer = window.dataLayer || []);
  dataLayer.push({
    event: eventName
  });
};

export const isPublished = (
  publishDate: string | null,
  unpublishDate?: string | null
) => {
  if (!publishDate && !unpublishDate) return true;
  const currentTime = Date.now();

  const _publishDate = publishDate
    ? new Date(publishDate).getTime()
    : currentTime;

  const _unpublishDate = unpublishDate
    ? new Date(unpublishDate).getTime()
    : null;

  return _unpublishDate
    ? _publishDate <= currentTime && _unpublishDate > currentTime
    : _publishDate <= currentTime;
};


export const poll = (fn: () => {}, timeout = 2000, interval = 100) => {
  const endTime = Number(new Date()) + timeout;

  const checkCondition = function (resolve: (value: unknown) => void, reject: (reason?: any) => void) {
    const result = fn();
    if (result) {
      resolve(result);
    } else if (Number(new Date()) < endTime) {
      setTimeout(checkCondition, interval, resolve, reject);
    } else {
      reject(new Error('timed out for: ' + fn));
    }
  };

  return new Promise(checkCondition);
};