// FIXME: Fix TS
import React, {
  createContext,
  useState,
  useEffect,
  SyntheticEvent,
  ReactNode
} from "react";
import Client from "shopify-buy";
import { useLazyQuery } from "@apollo/client";
import { BundleProduct, Customer, DefaultValues } from "./ContextTypes";
import { getCustomerQuery } from "./getCustomerQuery";
import { ShopifyProduct } from "../components/@types/PDP";
import { isEmpty } from "lodash-es";
import { decodeShopifyId, sendS2StikTokEvent } from "../utils/utils";
import { Checkout } from "../components/Cart/types";

import littledata from "@littledata/headless-shopify-sdk";

const client = Client.buildClient({
  domain: "timelesslacquer.myshopify.com",
  storefrontAccessToken: "db64e19e5db4ccefe89538c76cce767a"
});

// const getCheckout = async () => {
//   const checkout = client.checkout.create().then(checkout => { return checkout })
//   return await checkout.then(result => result);
// }

const isBrowser = typeof window !== "undefined";

const checkIfCustomer = () => {
  if (isBrowser) {
    const token = window.localStorage.getItem("caToken");
    const tokenExpires = window.localStorage.getItem("caTokenExpires");
    const currentDate = new Date().getTime();
    if (tokenExpires && currentDate >= Date.parse(tokenExpires)) {
      window.localStorage.removeItem("caTokenExpires");
      window.localStorage.removeItem("caToken");
    }
    if (
      token &&
      token?.length > 0 &&
      tokenExpires &&
      tokenExpires?.length > 0
    ) {
      return currentDate < Date.parse(tokenExpires);
    } else {
      return false;
    }
  } else {
    return false;
  }
};
const defaultValues: DefaultValues = {
  isCartOpen: false,
  isMobileNavOpen: false,
  isDesktopNavOpen: false,
  isSearchOpen: false,
  isCustomer: false,
  isLoading: false,
  customerData: null,
  setIsCustomer: () => {},
  toggleCartOpen: () => {},
  toggleMobileNavOpen: () => {},
  toggleDesktopNavOpen: () => {},
  toggleSearchOpen: () => {},
  toggleSearchClose: () => {},
  cart: [],
  addProductToCart: () => {},
  addStoreFrontAPIProductToCart: async () => {
    return false;
  },
  addBundleToCart: () => {},
  addBulkToCart: () => {},
  removeProductFromCart: () => {},
  removeBundleFromCart: () => {},
  updateBundleQty: () => {},
  updateLineItem: () => {},
  checkCoupon: async (coupon: string) => {
    return false;
  },
  removeCoupon: () => {},
  client,
  checkout: null,
  loadingText: "Loading...",
  toggleLoading: () => {},
  bundleAdded: false,
  checkIfCustomer: () => false,
  removeCustomerData: () => {},
  GoogleClientId: null,
  showOffer: false,
  handleShowOffer: (bool: boolean) => {}
};

export const StoreContext = createContext(defaultValues);

export const StoreProvider = ({ children }: { children: ReactNode }) => {
  const [checkout, setCheckout] = useState(defaultValues.checkout);
  const [isCartOpen, setCartOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(defaultValues.loadingText);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isDesktopNavOpen, setDesktopNavOpen] = useState(false);
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [isCustomer, setCustomer] = useState<boolean>(() => {
    return checkIfCustomer();
  });
  const [customerData, setCustomerData] = useState<Customer | null>(null);
  const [GoogleClientId, setGoogleClientId] = useState<string | null>(null);

  const [showOffer, setShowOffer] = useState(false);
  const handleShowOffer = (bool: boolean) => setShowOffer(bool);

  const toggleCartOpen = () => setCartOpen(!isCartOpen);
  const toggleMobileNavOpen = () => {
    const body = document.body;
    if (body && !isMobileNavOpen) {
      body.classList.add("mobile-nav-open");
    } else if (body) {
      body.classList.remove("mobile-nav-open");
    }
    setMobileNavOpen(!isMobileNavOpen);
  };
  const toggleDesktopNavOpen = () => setDesktopNavOpen(!isDesktopNavOpen);
  const toggleSearchOpen = () => {
    if (isDesktopNavOpen) setDesktopNavOpen(false);
    setSearchOpen(true);
  };
  const toggleSearchClose = () => {
    setSearchOpen(false);
  };
  
  const toggleLoading = (value: boolean) => setLoading(value);
  const setIsCustomer = (value: boolean) => setCustomer(value);
  const removeCustomerData = () => setCustomerData(null);

  useEffect(() => {
    initializeCheckout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // GA360 Remove after sunset
  const getGoogleClientId = () => {
    try {
      if (isBrowser) {
        return new Promise(resolve => {
          // gtag only makes 'ga' function available after the library loads
          // so we have to stub it if undefined here
          window.ga =
            window.ga ||
            function() {
              (window.ga.q = window.ga.q || []).push(arguments); //eslint-disable-line
            };
          window.ga.l = +new Date();

          const fallback = window.setTimeout(function() {
            //after 4 seconds, assume the script is blocked
            resolve("");
          }, 4000);
          window.ga(function() {
            // this function is called after GA library initializes
            window.clearTimeout(fallback);
            const tracker = window.ga.getAll()[0];
            const clientId = tracker && tracker.get("clientId");
            return resolve(clientId);
          });
          // @ts-expect-error [FIXME: TS!!!]
        }).then(clientId => setGoogleClientId(clientId));
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const [littledataAttribs, setLittledataAttribs] = useState<any[] | null>(
    null
  );

  useEffect(() => {
    if (!isEmpty(checkout)) {
      getGoogleClientId(); // GA360 Remove after sunset
      littledata
        .fetchClientIds({
          ga4MeasurementId: "G-2RTQQ7J54E"
        })
        .then(result => setLittledataAttribs(result)); // GA4
    }
    return () => {};
  }, [checkout]);

  useEffect(() => {
    if (littledataAttribs) {
      const checkoutId = checkout?.id;
      if (checkoutId) {
        const input = { customAttributes: littledataAttribs };

        // @ts-expect-error [FIXME: TS!!!]
        client.checkout.updateAttributes(checkoutId, input);
        littledata.sendCheckoutToLittledata(checkoutId).then(res => {
          // console.log({ res });
        });
      }
    }
  }, [littledataAttribs]);

  // GA360 Remove after sunset
  useEffect(() => {
    if (GoogleClientId) {
      const checkoutId = checkout?.id;
      const input = {
        customAttributes: [{ key: "google-clientID", value: GoogleClientId }]
      };
      // @ts-expect-error [FIXME: TS!!!]
      client.checkout.updateAttributes(checkoutId, input);
    }
  }, [GoogleClientId]);

  const GET_CUSTOMER = getCustomerQuery();

  const [getCustomer, { error }] = useLazyQuery(GET_CUSTOMER, {
    onCompleted: customer => {
      setCustomerData(customer);
      try {
        // @ts-expect-error
        if (isBrowser && typeof FS !== "undefined") {
          // @ts-expect-error
          FS.identify(customer.customer.id, {
            displayName: customer.customer.displayName,
            email: customer.customer.email
          });
        } else {
          // console.log('FS is not present')
        }
      } catch (error) {
        console.log(error);
      }
    }
  });

  if (error) {
    console.log(error);
  }

  useEffect(() => {
    if (isCustomer && isBrowser) {
      getCustomer({
        variables: { accessToken: window.localStorage.getItem("caToken") }
      });
    }
    return () => {};
  }, [isCustomer]);

  const getNewId = async () => {
    try {
      const newCheckout: any = await client.checkout.create();
      const _newCheckout: Checkout = newCheckout;
      if (isBrowser) {
        localStorage.setItem("checkout_id", _newCheckout.id);
      }
      return _newCheckout;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const initializeCheckout = async () => {
    try {
      // Check if id exists
      const currentCheckoutId = isBrowser
        ? localStorage.getItem("checkout_id")
        : null;

      let newCheckout;

      if (currentCheckoutId) {
        // If id exists, fetch checkout from Shopify
        newCheckout = await client.checkout.fetch(currentCheckoutId);
        if (!newCheckout || newCheckout.completedAt) {
          newCheckout = await getNewId();
        }
      } else {
        // If id does not, create new checkout
        newCheckout = await getNewId();
      }

      // Set checkout to state
      setCheckout(newCheckout as Checkout);
    } catch (e) {
      console.error(e);
    }
  };

  const sendDataToKlaviyo = (
    item: ShopifyProduct,
    cart: Checkout,
    quantity: number,
    variantSku?: string
  ) => {
    try {
      const data = {
        $value: Number(cart.subtotalPrice),
        AddedItemProductName: item.title,
        AddedItemProductID: item.id,
        AddedItemSKU: variantSku ? variantSku : item.variants[0].sku,
        AddedItemCategories: item.productType,
        AddedItemImageURL: item.media?.[0]?.image.originalSrc || "",
        AddedItemURL: `https://www.dipwell.co/products/${item.handle}`,
        AddedItemPrice: Number(item.variants[0].price),
        AddedItemQuantity: quantity,
        ItemNames: cart.lineItems.map(item => item.title),
        CheckoutURL: cart.webUrl.replace(
          "timelesslacquer.myshopify.com",
          "shop.dipwell.co"
        ),
        Items: cart.lineItems.map(lineItem => {
          return {
            ProductID: lineItem.id,
            SKU: lineItem.variant.sku,
            ProductName: lineItem.title,
            Quantity: lineItem.quantity,
            ItemPrice: Number(lineItem.variant.price),
            RowTotal: Number(lineItem.variant.price) * lineItem.quantity,
            ProductURL: `https://www.dipwell.co/products/${lineItem.variant.product.handle}`,
            ImageURL: lineItem.variant.image.src,
            ProductCategories: ""
          };
        })
      };
      window._learnq.push(["track", "Added to Cart", data]);
    } catch (error) {
      console.log(error);
    }
  };

  // [REVISIT] ADD event tracking
  const addStoreFrontAPIProductToCart = async (
    variantId: string,
    quantity: number = 1,
    product: any,
    showModal = false
  ) => {
    try {
      // setLoading(true);
      // setLoadingText('Adding product to cart...')

      const lineItems = [
        {
          variantId,
          quantity
        }
      ];
      const newCheckout: any = await client.checkout.addLineItems(
        // @ts-expect-error [FIXME: TS!!!]
        checkout.id,
        lineItems
      );
      // setLoadingText('Added!');

      setCheckout(newCheckout as Checkout);
      if (showModal) {
        // setLoading(false);
        // setLoadingText(defaultValues.loadingText);
        window.dispatchEvent(
          new CustomEvent("OPEN_ATC_MODAL", { detail: product })
        );
      } else {
        // setTimeout(() => {
        //   setLoading(false);
        //   setLoadingText(defaultValues.loadingText);
        // }, 1000);
      }
      return true;
    } catch (e) {
      // setLoadingText('Something went wrong! Please try again.');
      // setTimeout(() => {
      //   setLoading(false);
      //   setLoadingText(defaultValues.loadingText);
      // }, 1000);
      console.error(e);
      return false;
    }
  };

  const addProductToCart = async (
    variantId: string,
    quantity: number = 1,
    product: {} | null = null,
    showModal: boolean = true
  ) => {
    try {
      const eventID = checkout?.id || "";
      if (customerData) {
        const {
          customer: { email, phone, id: userID }
        } = customerData;
        const customerDataObject = {
          email,
          phone,
          userID,
          eventID,
          eventName: "AddToCart"
        };
        sendS2StikTokEvent(customerDataObject); // make sure to add to BYOB page
      } else {
        sendS2StikTokEvent({ eventName: "AddToCart", eventID });
      }
    } catch (error) {
      console.log(error);
    }
    try {
      setLoading(true);
      setLoadingText("Adding product to cart...");
      const lineItems = [
        {
          variantId,
          quantity
        }
      ];
      const newCheckout: any = await client.checkout.addLineItems(
        // @ts-expect-error [FIXME: TS!!!]
        checkout.id,
        lineItems
      );
      setLoadingText("Added!");
      if (product) {
        // @ts-expect-error [FIXME: TS!!!]
        sendDataToKlaviyo(product, newCheckout, quantity);
      }

      setCheckout(newCheckout as Checkout);
      if (showModal) {
        setLoading(false);
        setLoadingText(defaultValues.loadingText);
        window.dispatchEvent(
          new CustomEvent("OPEN_ATC_MODAL", { detail: product })
        );
      } else {
        setTimeout(() => {
          setLoading(false);
          setLoadingText(defaultValues.loadingText);
        }, 1000);
      }
    } catch (e) {
      setLoadingText("Something went wrong! Please try again.");
      setTimeout(() => {
        setLoading(false);
        setLoadingText(defaultValues.loadingText);
      }, 1000);
      console.error(e);
    }
  };
  const [bundleAdded, setBundleAdded] = useState(false);

  const addBundleToCart = async (
    variantIds: string[],
    bundleProducts: string[],
    bundleProduct: BundleProduct,
    showModal: boolean = true
  ) => {

    const kitId = bundleProduct.variants.find(
            variant => variant.title.toLowerCase() === `pick ${variantIds.length}`
          )?.storefrontId

    setLoadingText("Adding bundle to cart...");
    setLoading(true);

    const lineItems = [
      {
        variantId: kitId || "",
        quantity: 1,
        customAttributes: [
          {
            key: "Bundle",
            value: variantIds.map(id => decodeShopifyId(id)).join("; ")
          },
          { key: "Includes ", value: bundleProducts.join("; ") }
        ]
      }
    ];

    variantIds.forEach(id => {
      lineItems.push({
        variantId: id,
        quantity: 1,
        customAttributes: [
          { key: "Bundle item", value: "" },
          { key: "Type ", value: "Bundle item" }
        ]
      });
    });
    if (!checkout) return;

    client.checkout.addLineItems(checkout.id, lineItems).then(newCheckout => {
      setLoadingText("Added!");
      setLoading(false);
      if (showModal) {
        window.dispatchEvent(
          new CustomEvent("OPEN_ATC_MODAL", { detail: bundleProduct })
        );
      } else {
        toggleCartOpen();
      }
      setBundleAdded(true);
      setBundleAdded(false);
      setLoadingText(defaultValues.loadingText);
      // @ts-expect-error [FIXME: TS!!!]
      setCheckout(newCheckout as Checkout);

      // Klaviyo --- START
      const variant = bundleProduct.variants.find(
        item => item.storefrontId === kitId
      );
      // @ts-expect-error [FIXME: TS!!!]
      sendDataToKlaviyo(bundleProduct, newCheckout, 1, variant?.sku || "");
      // Klaviyo --- END
      try {
        const eventID = newCheckout?.id.toString();
        if (customerData) {
          const {
            customer: { email, phone, id: userID }
          } = customerData;
          const customerDataObject = {
            email,
            phone,
            userID,
            eventID,
            eventName: "AddToCart"
          };
          sendS2StikTokEvent(customerDataObject);
        } else {
          sendS2StikTokEvent({ eventName: "AddToCart", eventID });
        }
      } catch (error) {
        console.log(error);
      }
    });
  };

  const addBulkToCart = async (variantIds: string[]) => {
    try {
      setLoadingText("Adding products to cart...");
      setLoading(true);
      const lineItemsToUpdate = variantIds.map(variantId => {
        return {
          variantId,
          quantity: 1
        };
      });

      client.checkout
        .addLineItems(
          // @ts-expect-error
          checkout.id,
          lineItemsToUpdate
        )
        .then(newCheckout => {
          // @ts-expect-error
          setCheckout(newCheckout);
          setLoading(false);
          setLoadingText(defaultValues.loadingText);
          toggleCartOpen();
        })
        .catch(error => {
          setLoadingText("Something went wrong. Please try again.");
          setTimeout(() => {
            setLoading(false);
            setLoadingText(defaultValues.loadingText);
          }, 1500);
          console.error(error);
        });
    } catch (error) {
      setLoadingText("Something went wrong. Please try again.");
      setTimeout(() => {
        setLoading(false);
        setLoadingText(defaultValues.loadingText);
      }, 1500);
      console.error(error);
    }
  };

  // @ts-expect-error [FIXME: TS!!!]
  const removeProductFromCart = async lineItemId => {
    try {
      if (!checkout) return;
      setLoading(true);

      const newCheckout: any = await client.checkout.removeLineItems(
        checkout.id,
        [lineItemId]
      );
      setCheckout(newCheckout as Checkout);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  interface LineItem {
    id: string;
    totalQty: number;
    qty: number;
    isBundleParent: boolean;
  }

  const removeBundleFromCart = async (
    lineItemsInfo: LineItem[],
    bundleQty: number
  ) => {
    try {
      setLoadingText("Removing bundle from cart...");
      setLoading(true);
      const lineItemsToUpdate = lineItemsInfo.map(item => {
        return { id: item.id, quantity: item.totalQty - item.qty };
      });
      if (!checkout) return;
      client.checkout
        .updateLineItems(checkout?.id, lineItemsToUpdate)
        .then((newCheckout: unknown) => {
          setCheckout(newCheckout as Checkout);
          setLoadingText("Removed!");
          setLoading(false);
          setLoadingText(defaultValues.loadingText);
        })
        .catch(e => {
          setLoadingText("Something went wrong. Please try again.");
          console.log("Error removing bundle from cart: ", e);
          setTimeout(() => {
            setLoading(false);
            setLoadingText(defaultValues.loadingText);
          }, 1500);
        });
    } catch (error) {
      setLoadingText("Something went wrong. Please try again.");
      setTimeout(() => {
        setLoading(false);
        setLoadingText(defaultValues.loadingText);
      }, 1500);
      console.log("Error removing bundle from try catch: ", error);
    }
  };

  // @ts-expect-error [FIXME: TS!!!]
  const updateLineItem = async (lineItemId, currentQty, e) => {
    try {
      const qty = e.target.classList.contains("submit__button--minus")
        ? currentQty - 1
        : currentQty + 1;
      if (e.target.classList.contains("submit__button--minus")) {
        e.target.nextElementSibling.value = currentQty - 1;
      } else {
        e.target.previousElementSibling.value = currentQty + 1;
      }
      const lineItemsToUpdate = [{ id: lineItemId, quantity: qty }];
      // Update the line item on the checkout (change the quantity or variant)
      const newCheckout: any = await client.checkout.updateLineItems(
        // @ts-expect-error [FIXME: TS!!!]
        checkout.id,
        lineItemsToUpdate
      );
      setCheckout(newCheckout as Checkout);
    } catch (error) {
      console.error(error);
    }
  };

  const updateBundleQty = async (
    lineItemInfo: { id: string; totalQty: number; qty: number }[],
    e: SyntheticEvent,
    bundleQty: number,
    isDecrement = false
  ) => {
    e.persist();
    const button = e.target as HTMLButtonElement;
    const newBundleQty = isDecrement ? bundleQty - 1 : bundleQty + 1;

    try {
      if (!e.target) return;
      const input = isDecrement
        ? (button.nextElementSibling as HTMLInputElement)
        : (button.previousElementSibling as HTMLInputElement);
      if (input) input.value = newBundleQty.toString();
    } catch (error) {
      console.log(error);
    }

    try {
      setLoadingText("Updating quantity...");
      setLoading(true);
      const lineItemsToUpdate = lineItemInfo.map(item => {
        const valueToAdd = item.qty / bundleQty;
        return {
          id: item.id,
          quantity: isDecrement
            ? item.totalQty - valueToAdd
            : item.totalQty + valueToAdd
        };
      });
      if (!checkout) return;
      client.checkout
        .updateLineItems(checkout.id, lineItemsToUpdate)
        .then((newCheckout: unknown) => {
          setCheckout(newCheckout as Checkout);
          setLoadingText("Updated!");
          setLoading(false);
          setLoadingText(defaultValues.loadingText);
        })
        .catch(e => {
          setLoadingText("Something went wrong. Please try again.");
          console.log("Error updating qty of line item: ", e);
          setTimeout(() => {
            setLoading(false);
            setLoadingText(defaultValues.loadingText);
          }, 1500);
        });
    } catch (e) {
      setLoadingText("Something went wrong. Please try again.");
      console.log("Error updating qty of line item: ", e);
      setTimeout(() => {
        setLoading(false);
        setLoadingText(defaultValues.loadingText);
      }, 1500);
    }
  };

  const checkCoupon = async (coupon: string) => {
    setLoading(true);
    if (!checkout) return false; // False indicates the error
    const newCheckout: any = await client.checkout.addDiscount(
      checkout.id,
      coupon
    );
    if (newCheckout.userErrors.length === 0) {
      setCheckout(newCheckout as Checkout);
      setLoading(false);
      return true;
    } else {
      setLoading(false);
      return false;
    }
  };
  // @ts-expect-error [FIXME: TS!!!]
  const removeCoupon = async coupon => {
    setLoading(true);
    const newCheckout: any = await client.checkout.removeDiscount(
      // @ts-expect-error [FIXME: TS!!!]
      checkout.id,
      // @ts-expect-error [FIXME: TS!!!]
      coupon
    );
    setCheckout(newCheckout as Checkout);
    setLoading(false);
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        checkout,
        addProductToCart,
        addStoreFrontAPIProductToCart,
        // @ts-expect-error [FIXME: TS!!!]
        addBundleToCart,
        addBulkToCart,
        toggleCartOpen,
        toggleMobileNavOpen,
        toggleDesktopNavOpen,
        toggleSearchOpen,
        toggleSearchClose,
        toggleLoading,
        setIsCustomer,
        isSearchOpen,
        isCustomer,
        customerData,
        checkIfCustomer,
        removeCustomerData,
        isCartOpen,
        isMobileNavOpen,
        isDesktopNavOpen,
        removeProductFromCart,
        // @ts-expect-error [FIXME: TS!!!]
        removeBundleFromCart,
        // @ts-expect-error [FIXME: TS!!!]
        updateBundleQty,
        bundleAdded,
        checkCoupon,
        removeCoupon,
        isLoading,
        loadingText,
        showOffer,
        handleShowOffer,
        updateLineItem,
        client
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
