import { gql } from '@apollo/client';

export const getCustomerQuery = () => {
  return gql`
  query GetCustomer($accessToken: String!) {
    customer(customerAccessToken: $accessToken) {
    firstName
    lastName
    displayName
    id
    email
    phone
    tags
    acceptsMarketing
    defaultAddress {
      id
      formatted
      address1
      address2
      company
      zip
      province
      provinceCode
      city
      phone
    }
    orders(first: 250, reverse: true) {
      edges {
        node {
          id
          orderNumber
          financialStatus
          fulfillmentStatus
          canceledAt
          cancelReason
          processedAt
          name
          phone
          shippingAddress {
            formatted
          }
          totalPrice{
            amount
          }
          totalTax {
            amount
          }
          totalRefunded {
            amount
          }
          currentTotalTax {
            amount
          }
          totalShippingPrice {
            amount
          }
          originalTotalPrice {
            amount
          }
          currentTotalPrice {
            amount
          }
          lineItems (first: 250){
            edges {
              node {
                title
                quantity
                variant {
                  image {
                    transformedSrc (maxWidth: 300)
                    originalSrc
                  }
                  product {
                    tags
                    handle
                    id
                  }
                  title
                  sku
                  selectedOptions {
                    name
                    value
                  }
                  id
                  compareAtPrice {
                    amount
                  }
                }
                currentQuantity
                customAttributes {
                  key
                  value
                }
                discountedTotalPrice {
                  amount
                }
                discountAllocations {
                  allocatedAmount {
                    amount
                  }
                  discountApplication {
                    allocationMethod
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  }
`
}