import React from 'react';
import { useFacebook } from '../utils/hooks';

// https://developers.facebook.com/docs/messenger-platform/discovery/customer-chat-plugin
const CustomerChat = React.memo(function CustomerChat() {
  const timeoutRef = React.useRef();

  // Initialize Facebook widget(s) in 2 seconds after
  // the component is mounted.
  useFacebook({ xfbml: false }, (FB: { XFBML: { parse: () => void; }; }) => {
    if (timeoutRef.current !== null) {
      timeoutRef.current = setTimeout(() => {
        const isHidden = document.body.classList.contains('fb-chat-hidden');
        if (isHidden) return;
        const el = document.createElement('div');
        el.className = 'fb-customerchat';
        el.setAttribute('attribution', 'install_email');
        el.setAttribute('page_id', '1944412742456965');
        el.setAttribute('ptheme_color', '#00B2A9');
        el.setAttribute('plogged_in_greeting', 'Hi there &amp; welcome to the DipWell community! How can we assist you today?');
        el.setAttribute('plogged_out_greeting', 'Hi there &amp; welcome to the DipWell community! How can we assist you today?');
        // el.setAttribute('pgreeting_dialog_display', '...');
        // el.setAttribute('pgreeting_dialog_delay', '...');
        // el.setAttribute('pminimized', 'false');
        document.body.appendChild(el);
        FB.XFBML.parse();
      }, 2000);
    }
  });

  return null;
});

export default CustomerChat;