import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { StoreProvider } from './src/context/StoreContext.tsx';
import client from './src/components/apolloClient.ts';
import CustomerChat from './src/components/CustomerChat.tsx';
// eslint-disable-next-line react/prop-types
// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <StoreProvider>{element}</StoreProvider>
    <CustomerChat />
  </ApolloProvider>
);

