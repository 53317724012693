/* eslint-disable no-return-assign */
/* eslint-disable import/prefer-default-export */
import { React, useEffect, useLayoutEffect, useState } from 'react';
import { debounce } from 'lodash-es';

export function useLockBodyScroll() {
  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      const originalStyle = window.getComputedStyle(document.body).overflow;
      // Prevent scrolling on mount
      document.body.style.overflow = 'hidden';
      // Re-enable scrolling when component unmounts
      return () => document.body.style.overflow = originalStyle;
    }
  }, []); // Empty array ensures effect is only run on mount and unmount
}

export function useLockBodyHTMLScroll() {
  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      const originalStyle = window.getComputedStyle(document.body).overflow;
      const originalStyleHTML = window.getComputedStyle(document.documentElement).overflow;
      // Prevent scrolling on mount
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      // Re-enable scrolling when component unmounts
      return () => {
        document.body.style.overflow = originalStyle;
        document.documentElement.style.overflow = originalStyleHTML;
      }
    }
  }, []); // Empty array ensures effect is only run on mount and unmount
}

export function useRevealOverlay() {
  useLayoutEffect(() => {
    const overlay = document.querySelector('.overlay');
    overlay.style.display = 'block';
    return () => overlay.style.display = 'none';
  }, []);
}

// useFacebook
let promise;

export function useFacebook(options, cb) {
  if (typeof options === 'function') {
    cb = options;
  }

  useEffect(() => {
    if (promise) {
      promise.then(cb);
    } else {
      promise = new Promise(resolve => {
        // https://developers.facebook.com/docs/javascript/reference/FB.init
        window.fbAsyncInit = () => {
          window.FB.init({
            xfbml: true,
            version: 'v9.0'
          });
          resolve(window.FB);
        };

        const script = document.createElement('script');
        script.src = `https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js`; // prettier-ignore
        script.id = 'facebook-jssdk'
        document.head.appendChild(script);
      });
      promise.then(cb);
    }
  }, []);
}