exports.components = {
  "component---src-components-byob-bd-pwrapper-tsx": () => import("./../../../src/components/BYOB/BDPwrapper.tsx" /* webpackChunkName: "component---src-components-byob-bd-pwrapper-tsx" */),
  "component---src-components-collection-tsx": () => import("./../../../src/components/Collection.tsx" /* webpackChunkName: "component---src-components-collection-tsx" */),
  "component---src-components-product-tsx": () => import("./../../../src/components/Product.tsx" /* webpackChunkName: "component---src-components-product-tsx" */),
  "component---src-components-sanity-blog-blog-post-tsx": () => import("./../../../src/components/SanityBlog/BlogPost.tsx" /* webpackChunkName: "component---src-components-sanity-blog-blog-post-tsx" */),
  "component---src-components-sanity-blog-foot-care-blog-post-tsx": () => import("./../../../src/components/SanityBlog/FootCareBlogPost.tsx" /* webpackChunkName: "component---src-components-sanity-blog-foot-care-blog-post-tsx" */),
  "component---src-components-sanity-blog-tutorial-post-tsx": () => import("./../../../src/components/SanityBlog/TutorialPost.tsx" /* webpackChunkName: "component---src-components-sanity-blog-tutorial-post-tsx" */),
  "component---src-components-sanity-page-components-sanity-page-tsx": () => import("./../../../src/components/SanityPageComponents/SanityPage.tsx" /* webpackChunkName: "component---src-components-sanity-page-components-sanity-page-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-blog-legacy-tutorials-tsx": () => import("./../../../src/pages/blogLegacy/tutorials.tsx" /* webpackChunkName: "component---src-pages-blog-legacy-tutorials-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-bundle-builder-gel-tsx": () => import("./../../../src/pages/bundle-builder-gel.tsx" /* webpackChunkName: "component---src-pages-bundle-builder-gel-tsx" */),
  "component---src-pages-bundle-builder-og-tsx": () => import("./../../../src/pages/bundle-builder-og.tsx" /* webpackChunkName: "component---src-pages-bundle-builder-og-tsx" */),
  "component---src-pages-cart-tsx": () => import("./../../../src/pages/cart.tsx" /* webpackChunkName: "component---src-pages-cart-tsx" */),
  "component---src-pages-dipkit-tsx": () => import("./../../../src/pages/dipkit.tsx" /* webpackChunkName: "component---src-pages-dipkit-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-promotion-terms-conditions-tsx": () => import("./../../../src/pages/promotion-terms-conditions.tsx" /* webpackChunkName: "component---src-pages-promotion-terms-conditions-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-ugc-terms-conditions-tsx": () => import("./../../../src/pages/ugc-terms-conditions.tsx" /* webpackChunkName: "component---src-pages-ugc-terms-conditions-tsx" */)
}

