import { ApolloClient, InMemoryCache } from "@apollo/client";
import fetch from "isomorphic-fetch";

const client = new ApolloClient({
  // @ts-expect-error
  fetch,
  uri: "https://timelesslacquer.myshopify.com/api/2022-10/graphql.json",
  cache: new InMemoryCache(),
  headers: {
    "X-Shopify-Storefront-Access-Token": "db64e19e5db4ccefe89538c76cce767a"
  }
});

export default client;
